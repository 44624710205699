import request from '@/utils/request';

//列表
export function companyStorageList(data) {
  return request({
    url : '/companyStorage/companyStorageList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompanyStorage(data) {
  return request({
    url : '/companyStorage/createCompanyStorage',
    method : 'post',
    data : data
  })
}
//修改
export function updateCompanyStorage(data) {
  return request({
    url :'/companyStorage/updateCompanyStorage',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompanyStorage(data) {
  return request({
    url : '/companyStorage/deleteCompanyStorage',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompanyStorage(data) {
  return request({
    url : '/companyStorage/batchDeleteCompanyStorage',
    method : 'post',
    data : data
  })
}
